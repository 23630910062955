body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  ::-webkit-scrollbar {
    display: none;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  
  }
  
  
  .receipt-button {
    max-width: 300px;
    font-family: "Poppins";
    border: 1px solid orange;
    color: white;
    padding: 15px 32px;
    text-align: center;
    background: orange;
  
    display: inline-block;
    font-size: 16px;
    margin: 20px 0px;
    cursor: pointer;
    transition: 0.4s;
  
    @media screen and (max-width: 1100px){
      width: 100% !important;
      margin: 30px 0px;
    }
  
  }
  
  .receipt-button a {
    text-decoration: none;
    color: black;
    transition: 0.4s;
  
  }
  
  .receipt-button:hover {
    transition: 0.4s;
    background-color: black;
  }
  
  .receipt-button:hover a {
    transition: 0.4s;
  
    color: white;
  }
  
  .receipt-close-icon{
    background: #f5f5f5;
    padding: 15px;
    border-radius: 50%;
    
    font-size: 25px;
    max-width: 1500px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  .recepit-container{
    display: none;
    position: fixed;
    width: 100%;
    height: 100dvh;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background: rgb(255, 255, 255);
  
    @media screen and (max-width: 1100px){
      overflow-y: scroll !important;
    }
  }
  .receipt-place{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 70px;
  
    @media screen and (max-width: 1100px){
      height: auto !important;
      gap: 10px !important;
      text-align: center !important;
      padding: 50px 10px !important;
    }
  }
  
  .receipt-flex{
  
    max-width: 1500px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 60px;
  
    @media screen and (max-width: 1100px){
      flex-direction: column !important;
    }
  }
  .receipt-content{
    flex: 1;
  }
  .download-link{
    font-weight: bolder;
    background: none;
    border: none;
    font-family: "Poppins";
  }
  .download-link:hover{
    cursor: pointer;
  }
  .receipt-content h2{
    font-family: "Domine";
    font-size: 30px;
    line-height: 1.5;
  }
  .receipt-content h2 a{
    color: orange;
  }
  .receipt-content p{
    font-family: "Poppins";
    font-size: 16px;
    line-height: 2;
    margin: 20px 0px;
  }
  
  
  .recepit-images{
    flex: 1;
    display: flex;
    gap: 20px;
  
    @media screen and (max-width: 1100px){
      flex-direction: column !important;
    }
  }
  
  .image1{
    background-position: center;
    background-size: cover;
    width: 330px;
    height: 460px;
  
  }
  .image2{
    background-position: center;
    background-size: cover;
    width: 330px;
    height: 460px;
  }
  
  
  .receipt-info-container{
    background: orange;
    height: 40px;
    position: absolute;
    width: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 550px){
      font-size: 12px !important;
    }
  }
  .receipt-info-container:hover p{
    text-decoration: underline;
  }
  
  